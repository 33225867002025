const download_state = {
	is_open: false
}

const download = (state = download_state, action) => {
    switch (action.type) {
		case "OPEN_MODAL":
			return { 
				...state, 
				is_open: action.toggle
			};
		default:
			return state;
	}
};

export default download;