const lang_state = {
	lang : 'en'
}

const lang = (state = lang_state, action) => {
    switch (action.type) {
		case "CHANGE_LANG":
			return { ...state, lang: action.lang };
		default:
			return state;
	}
};

export default lang;