const guide_state = {
	tab : 'mode',
	limit : {
		mode : 1,
		car : 8,
		maps : 8,
		accessories : 8,
		pets: 8
	},
	current_page : 1,
	total_page: 1
}

const guide = (state = guide_state, action) => {
    switch (action.type) {
		case "CHANGE_GUIDE_TAB":
			return { 
				...state, 
				tab: action.tab,
				current_page : 1,
				total_page: (action.data) ? total_page(action.data.length, state, action.tab) : 1
			};
		case "CHANGE_PAGE":
			return {
				...state,
				current_page: change_page(action.count, state)
			}
		default:
			return state;
	}
};


function total_page(length, state, type) {
	let total_page = Math.floor(length / state.limit[type]);

	if (length % state.limit > 0) {
		total_page = total_page + 1;
	}

	if (total_page == 0) {
		total_page = 1;
	}
	
	return total_page
}

function change_page(count, state) {
	let current_page = state.current_page + count;

	if (current_page <= 1) {
		return 1;
	}

	if (current_page > state.total_page) {
		return state.total_page;
	}

	return current_page;
}

export default guide;