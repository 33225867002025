// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-guide-jsx": () => import("./../../../src/pages/guide.jsx" /* webpackChunkName: "component---src-pages-guide-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-templates-guide-jsx": () => import("./../../../src/templates/guide.jsx" /* webpackChunkName: "component---src-templates-guide-jsx" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/news.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-rate-jsx": () => import("./../../../src/templates/rate.jsx" /* webpackChunkName: "component---src-templates-rate-jsx" */)
}

