import { combineReducers } from "redux";
import home from "./home";
import news from "./news";
import guide from "./guide";
import mobil from "./mobil";
import lang from "./lang";
import download from "./download";

export default combineReducers({
	home, news, guide, mobil, lang, download
});
