const home_state = {
	tab : 'all'
}

const home = (state = home_state, action) => {
    switch (action.type) {
		case "CHANGE_NEWS_TAB":
			return { ...state, tab: action.tab };
		default:
			return state;
	}
};

export default home;